import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { transformUrl } from '../../../util/util';

const StyledImage = styled.section`
	@media (max-width: 29.9375em) {
		${(props) => props.mobileWidth && `max-width: ${props.mobileWidth}px;`}
	}

	img {
		width: 100%;
		height: auto;
		display: block;
	}
`;

const Image = (props) => {
	const { payload, image, mobileWidth } = props;
	const optimizedPayload = transformUrl(
		payload,
		'upload',
		'f_auto,q_auto:good,cs_srgb'
	);
	const optimizedRetina = image.retina
		? transformUrl(image.retina, 'upload', 'f_auto,q_auto:low,cs_srgb')
		: null;

	if (payload) {
		return (
			<StyledImage mobileWidth={mobileWidth}>
				<img
					src={optimizedPayload}
					alt={image.alt || null}
					title={image.alt || null}
					srcSet={
						image.scale ? `${optimizedRetina} ${image.scale}` : null
					}
				/>
			</StyledImage>
		);
	}
	return null;
};

Image.propTypes = {
	payload: PropTypes.string,
	image: PropTypes.shape({
		retina: PropTypes.string,
		scale: PropTypes.string,
		alt: PropTypes.string,
	}),
	mobileWidth: PropTypes.number,
};

export default Image;
