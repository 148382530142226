import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledProjectContainer = styled.article`
	position: relative;

	@media (max-width: 26.9375em) {
		padding: 8px 0 42px;
	}

	@media (min-width: 27em) and (max-width: 63.9375em) {
		padding: 6px 0 40px;
	}

	@media (min-width: 64em) {
		padding: 23px 0 50px;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 2px;
		background-color: black;
		left: 0;
	}

	&::before {
		top: 0;
	}

	&::after {
		bottom: 0;
	}

	.flex-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
	}
`;

const ProjectContainer = (props) => {
	const { children } = props;
	return <StyledProjectContainer>{children}</StyledProjectContainer>;
};

ProjectContainer.propTypes = {
	children: PropTypes.array,
};

export default ProjectContainer;
