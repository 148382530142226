import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import ProjectContainer from '../components/project/project-container';
import Title from '../components/project/title';
import Category from '../components/project/category';
import PrimaryContent from '../components/project/content/primary-content';
import Description from '../components/project/description';
import Info from '../components/project/info';
import SecondaryContent from '../components/project/content/secondary-content';
import settingsData from '../settings/settings.json';
import SEO from '../components/seo';

export default function Project({ data }) {
	const project = data.markdownRemark;
	const primaryContent = data.primaryContent.frontmatter.primary_content;
	const secondaryContent =
		data.secondaryContent.frontmatter.secondary_content;
	const seo = project.frontmatter.seo || '';
	const { siteTitle } = settingsData;

	return (
		<>
			<SEO
				title={`${project.frontmatter.title} | ${project.frontmatter.category} | ${siteTitle}`}
				description={seo.seoDescription}
			/>
			<ProjectContainer>
				<div className="flex-container">
					<Title title={project.frontmatter.title} />
					<Category category={project.frontmatter.category} />
				</div>
				{primaryContent && <PrimaryContent payload={primaryContent} />}
				<div className="flex-container">
					<Description description={project.fields.description} />
					<Info info={project.fields.info} />
				</div>
				{secondaryContent && (
					<SecondaryContent payload={secondaryContent} />
				)}
			</ProjectContainer>
		</>
	);
}

export const query = graphql`
	query ($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			fields {
				info
				description
				slug
			}
			frontmatter {
				title
				category
				seo {
					seoDescription
				}
			}
		}
		primaryContent: markdownRemark(fields: { slug: { eq: $slug } }) {
			frontmatter {
				primary_content {
					type
					hidden
					width
					video_file
					placeholder
					slider {
						slide
						retinaSlide
						scale
						alt
					}
				}
			}
		}
		secondaryContent: markdownRemark(fields: { slug: { eq: $slug } }) {
			frontmatter {
				secondary_content {
					type
					image_file
					retina_image_file
					scale
					alt
					video_file
					mobile_width
					placeholder
					slider {
						slide
						retinaSlide
						alt
					}
					autoplay
					hidden
					width
				}
			}
		}
	}
`;

Project.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.shape({
				title: PropTypes.string,
				category: PropTypes.string,
				seo: PropTypes.shape({
					seoDescription: PropTypes.string,
				}),
			}),
			fields: PropTypes.shape({
				description: PropTypes.string,
				info: PropTypes.string,
				slug: PropTypes.string,
			}),
		}),
		primaryContent: PropTypes.shape({
			frontmatter: PropTypes.shape({
				primary_content: PropTypes.arrayOf(PropTypes.object),
			}),
		}),
		secondaryContent: PropTypes.shape({
			frontmatter: PropTypes.shape({
				secondary_content: PropTypes.arrayOf(PropTypes.object),
			}),
		}),
	}),
};
