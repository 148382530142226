import React from 'react';
import PropTypes from 'prop-types';
import Slider from './content/slider/slider';

const SliderContainer = (props) => {
	const { payload, autoplay, gallery, maxWidth, mobileWidth } = props;
	const autoplayTimer = autoplay ? 4 : undefined;
	if (payload) {
		return (
			<>
				<Slider
					slides={payload}
					autoplayTimer={autoplayTimer}
					gallery={gallery}
					maxWidth={maxWidth}
					mobileWidth={mobileWidth}
				/>
			</>
		);
	}
	return null;
};

SliderContainer.propTypes = {
	payload: PropTypes.array,
	autoplay: PropTypes.bool,
	gallery: PropTypes.bool,
	maxWidth: PropTypes.number,
	mobileWidth: PropTypes.number,
};

export default SliderContainer;
