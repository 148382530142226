import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { transformUrl } from '../../../../util/util';

const StyledSlide = styled.div`
	pointer-events: none;
	img {
		height: auto;
		width: 100%;
		display: block;
	}
	max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
`;

const Slide = React.forwardRef((props, ref) => {
	const { content, width, sourceWidth } = props;

	const slide = transformUrl(
		content.slide,
		'upload',
		'f_auto,q_auto:good,cs_srgb'
	);
	const retinaSlide = content.retinaSlide
		? transformUrl(
				content.retinaSlide,
				'upload',
				'f_auto,q_auto:low,cs_srgb'
		  )
		: null;

	return (
		<StyledSlide maxWidth={width}>
			<img
				src={slide}
				alt={content.alt}
				srcSet={
					content.retinaSlide
						? `${retinaSlide} ${
								sourceWidth * 2
						  }w, ${slide} ${sourceWidth}w`
						: null
				}
				sizes={`(max-width: ${sourceWidth}px) 100vw, ${width}px`}
				ref={ref}
			/>
		</StyledSlide>
	);
});

Slide.propTypes = {
	content: PropTypes.shape({
		slide: PropTypes.string,
		retinaSlide: PropTypes.string,
		alt: PropTypes.string,
	}),
	width: PropTypes.number,
	sourceWidth: PropTypes.number,
};

Slide.displayName = 'Slide';

export default Slide;
