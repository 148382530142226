import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCategory = styled.section`
	margin-bottom: calc(27rem / 16);
	line-height: 1;

	@media (max-width: 26.9375em) {
		font-size: calc(21rem / 16);

		flex: 1 0 100%;
	}

	@media (min-width: 27em) and (max-width: 29.9375em) {
		font-size: calc(24rem / 16);

		flex: 1 0 100%;
	}

	@media (min-width: 30em) {
		flex: 1 1 auto;
	}

	@media (min-width: 30em) and (max-width: 79.9375em) {
		font-size: calc(42rem / 16);
	}

	@media (min-width: 80em) {
		font-size: calc(58rem / 16);
	}

	text-align: end;
`;

const Category = (props) => {
	const { category } = props;
	if (category) {
		return <StyledCategory>{category}</StyledCategory>;
	}
	return null;
};

Category.propTypes = {
	category: PropTypes.string,
};

export default Category;
