import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSliderContent = styled.div`
	transform: translateX(-${(props) => props.translate}px);
	transition: transform ease-in-out ${(props) => props.transition}s;
	height: 100%;
	width: ${(props) => props.width}px;
	display: flex;
`;

const SliderContent = (props) => {
	const { children, translate, transition, width, handleClick } = props;
	return (
		<StyledSliderContent
			translate={translate}
			transition={transition}
			width={width}
			// onClick={handleClick}
		>
			{children}
		</StyledSliderContent>
	);
};

SliderContent.propTypes = {
	children: PropTypes.array,
	translate: PropTypes.number,
	transition: PropTypes.number,
	width: PropTypes.number,
	// handleClick: PropTypes.func,
};

export default SliderContent;
