import { useState, useEffect } from 'react';

const useDeviceDetect = () => {
	const [isMobile, setMobile] = useState(false);

	useEffect(() => {
		const userAgent =
			typeof navigator === 'undefined' ? '' : navigator.userAgent;
		const mobile = Boolean(
			userAgent.match(
				/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
			)
		);
		const ipad =
			navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;

		setMobile(mobile || ipad);
	}, []);

	return isMobile;
};

export default useDeviceDetect;
