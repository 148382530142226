import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentWrapper from './component-wrapper';
import useSafari from '../../../util/use-safari';

const StyledSecondaryContent = styled.div`
	display: grid;
	justify-items: center;
	align-items: start;

	@media (max-width: 26.9375em) {
		grid-gap: calc(29rem / 16) 1em;
	}

	@media (min-width: 27em) {
		grid-gap: calc(36rem / 16) 1em;
	}

	@media (min-width: 48em) and (max-width: 79.9375em) {
		${(props) =>
			props.items <= 2
				? `
				grid-template-columns: repeat(${props.items}, auto);
				`
				: `
				grid-template-columns: repeat(2, auto);

				& > :last-child {
					grid-column: 1 / -1;
				}
		`}
	}

	@media (min-width: 80em) {
		grid-template-columns: repeat(${(props) => props.items}, auto);
		grid-row: 4 / 5;
	}

	${(props) =>
		props.safari &&
		`
			@media (max-width: 47.9375em) {
				grid-template-columns: 100%;
			}

			@media (min-width: 80em) {
				grid-template-columns: repeat(${props.items}, calc((100% / ${props.items}) - (1em / ${props.items})));
			}
	`}

	${(props) =>
		props.safari && props.items <= 2
			? `@media (min-width: 48em) and (max-width: 79.9375em) {
				grid-template-columns: repeat(${props.items}, calc((100% / ${props.items}) - (1em / ${props.items})));
			}`
			: ``}
`;

const SecondaryContent = (props) => {
	const { payload } = props;
	const items = payload.length;
	const safari = useSafari();

	return (
		<StyledSecondaryContent items={items} safari={safari}>
			{payload.map((content, index) => (
				<ComponentWrapper
					type={content.hidden}
					payload={
						content.image_file ||
						content.video_file ||
						content.slider
					}
					image={
						content.image_file && {
							retina: content.retina_image_file,
							scale: content.scale,
							alt: content.alt,
						}
					}
					placeholder={content.placeholder}
					key={`secondary-content0${index}`}
					autoplay={content.autoplay}
					maxWidth={content.width}
					mobileWidth={content.mobile_width}
				/>
			))}
		</StyledSecondaryContent>
	);
};

SecondaryContent.propTypes = {
	payload: PropTypes.arrayOf(PropTypes.object),
};

export default SecondaryContent;
