import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useSanitizeHtml from '../../util/use-sanitize-html';
import { sanitizeDefaults } from '../../util/util';

const StyledInfo = styled.section`
	text-align: right;

	@media (max-width: 53.9375em) {
		margin-bottom: calc(29rem / 16);
		flex: 1 0 100%;
	}

	@media (min-width: 54em) {
		margin-bottom: calc(59rem / 16);
		flex: 1 1 0;
		padding-left: 1em;
	}

	@media (max-width: 26.9375em) {
		font-size: calc(14rem / 16);
		line-height: calc(17rem / 16);
	}

	@media (min-width: 27em) and (max-width: 29.9375em) {
		font-size: 1rem;
		line-height: calc(19rem / 16);
	}

	@media (min-width: 30em) and (max-width: 79.9375em) {
		font-size: calc(20rem / 16);
		line-height: calc(23rem / 16);
	}

	@media (min-width: 80em) {
		font-size: calc(24rem / 16);
		line-height: calc(27rem / 16);
	}

	& a {
		text-decoration: underline;
	}

	& a:hover,
	& a:focus {
		text-decoration: none;
	}

	& a.margin-top {
		display: inline-block;
		margin-top: calc(1em + 4px);
	}
`;

const Info = (props) => {
	const { info } = props;
	const cleanInfo = useSanitizeHtml(info, sanitizeDefaults);

	if (info) {
		return (
			<StyledInfo
				dangerouslySetInnerHTML={{
					__html: cleanInfo,
				}}
			/>
		);
	}
	return null;
};

Info.propTypes = {
	info: PropTypes.string,
};

export default Info;
