import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { transformUrl } from '../../../util/util';

const StyledVideo = styled.section`
	@media (max-width: 29.9375em) {
		${(props) => props.mobileWidth && `max-width: ${props.mobileWidth}px;`}
	}

	video {
		width: 100%;
		height: auto;

		&:focus {
			outline: none; /* not my call */
		}
	}
`;

const Video = (props) => {
	const { payload, placeholder, mobileWidth } = props;

	const optimizedPlaceholder = transformUrl(
		placeholder,
		'upload',
		'f_auto,q_auto:good,cs_srgb'
	);

	const optimizedVideo = transformUrl(
		payload,
		'upload',
		'f_auto,q_auto:good'
	);

	if (payload) {
		return (
			<StyledVideo mobileWidth={mobileWidth}>
				{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
				<video
					preload="none"
					poster={optimizedPlaceholder}
					controls
					src={optimizedVideo}
					playsInline
				/>
			</StyledVideo>
		);
	}
	return null;
};

Video.propTypes = {
	payload: PropTypes.string,
	placeholder: PropTypes.string,
	mobileWidth: PropTypes.number,
};

export default Video;
