import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useResizeAware from 'react-resize-aware';
import SliderContent from './slider-content';
import Slide from './slide';
import Arrow from './arrow';
import SlideCounter from './slide-counter';

const StyledSlider = styled.section`
	position: relative;
	width: 100%;
	overflow: hidden;
	cursor: none;

	@media (max-width: 29.9375em) {
		${(props) => props.mobileWidth && `max-width: ${props.mobileWidth}px;`}
	}

	@media (min-width: 30em) {
		max-width: ${(props) =>
			props.maxWidth ? `${props.maxWidth}px` : `100%`};
	}

	${(props) =>
		props.gallery
			? `
			@media (max-width: 63.9375em) {
				margin: 0 auto calc(25rem / 16);
			}

			@media (min-width: 64em) {
				margin: 0 auto calc(40rem / 16);
			}
			`
			: ``}
`;

const Slider = (props) => {
	const [resizeListener, sizes] = useResizeAware();

	const { slides, autoplayTimer, gallery, maxWidth, mobileWidth } = props;

	const firstSlide = slides[0];
	const secondSlide = slides[1];
	const lastSlide = slides[slides.length - 1];

	const getSize = () => sizes.width;
	const size = getSize();

	const [state, setState] = useState({
		activeSlide: 0,
		translate: getSize(),
		transition: 0.45,
		_slides: [lastSlide, firstSlide, secondSlide],
	});

	const { translate, transition, _slides, activeSlide } = state;

	const [visibleCursor, setVisibleCursor] = useState(false);

	const transitionRef = useRef();
	const resizeRef = useRef();
	const autoPlayRef = useRef();
	const sliderRef = useRef();

	// const maxWidth = slideRef.current ? slideRef.current.naturalWidth : null;

	const handleResize = () => {
		setState({ ...state, translate: size, transition: 0 });
	};

	const smoothTransition = () => {
		// eslint-disable-next-line no-shadow
		let _slides = [];

		// last slide
		if (activeSlide === slides.length - 1) {
			_slides = [slides[slides.length - 2], lastSlide, firstSlide];
		}
		// first slide, reset initial render
		else if (activeSlide === 0) {
			_slides = [lastSlide, firstSlide, secondSlide];
		}
		// previous last slide and the next two slides that follow
		else _slides = slides.slice(activeSlide - 1, activeSlide + 2);

		setState({
			...state,
			_slides,
			transition: 0,
			translate: size,
		});
	};

	const nextSlide = () => {
		setState({
			...state,
			translate: translate + size,
			activeSlide:
				activeSlide === slides.length - 1 ? 0 : activeSlide + 1,
		});
	};

	const previousSlide = () => {
		setState({
			...state,
			translate: 0,
			activeSlide:
				activeSlide === 0 ? slides.length - 1 : activeSlide - 1,
		});
	};

	const showSlide = () => {
		const totalSlides =
			slides.length < 10 ? `0${slides.length}` : `${slides.length}`;
		const currentSlide =
			activeSlide < 9 ? `0${activeSlide + 1}` : `${activeSlide + 1}`;
		return `${currentSlide}/${totalSlides}`;
	};

	const handleHover = () => {
		setVisibleCursor(!visibleCursor);
	};

	useEffect(() => {
		if (autoplayTimer) {
			const play = () => {
				autoPlayRef.current();
			};

			const interval = setInterval(play, autoplayTimer * 1000);
			return () => clearInterval(interval);
		}
	}, [autoplayTimer]);

	useEffect(() => {
		autoPlayRef.current = nextSlide;
		transitionRef.current = smoothTransition;
		resizeRef.current = handleResize;
	});

	useEffect(() => {
		const isClient = typeof window === 'object';
		let unmounted = false;
		const slider = sliderRef.current;

		if (!isClient) {
			return false;
		}

		const smooth = (event) => {
			if (
				event.target.className.includes('SliderContent') &&
				!unmounted
			) {
				transitionRef.current();
			}
		};

		const resize = () => {
			if (!unmounted) {
				resizeRef.current();
			}
		};

		const transitionEnd = slider.addEventListener('transitionend', smooth);
		const onResize = window.addEventListener('resize', resize);

		return () => {
			slider.removeEventListener('transitionend', transitionEnd);
			window.removeEventListener('resize', onResize);
			unmounted = true;
		};
	}, []);

	useEffect(() => {
		if (transition === 0) setState({ ...state, transition: 0.45 });
	}, [state, transition]);

	useEffect(() => {
		if (translate === null) setState({ ...state, translate: 0 });
	}, [state, translate]);

	return (
		<StyledSlider
			maxWidth={maxWidth}
			gallery={gallery}
			onMouseOver={handleHover}
			onMouseOut={handleHover}
			onFocus={handleHover}
			onBlur={handleHover}
			ref={sliderRef}
			mobileWidth={mobileWidth}
		>
			{resizeListener}
			<SliderContent
				translate={translate}
				transition={transition}
				width={size * _slides.length}
				// handleClick={nextSlide}
			>
				{_slides.map((_slide, index) => (
					<Slide
						width={size}
						key={_slide + index}
						content={_slide}
						handleClick={nextSlide}
						// ref={slideRef}
						sourceWidth={maxWidth}
					/>
				))}
			</SliderContent>
			{!autoplayTimer && (
				<Arrow direction="left" handleClick={previousSlide} />
			)}
			{!autoplayTimer && (
				<Arrow direction="right" handleClick={nextSlide} />
			)}
			<SlideCounter
				slide={showSlide}
				cursor={visibleCursor ? `cursor` : undefined}
				sliderRef={sliderRef}
			/>
		</StyledSlider>
	);
};

Slider.defaultProps = {
	slides: [],
	autoplayTimer: null,
};

Slider.propTypes = {
	slides: PropTypes.array,
	autoplayTimer: PropTypes.number,
	gallery: PropTypes.bool,
	maxWidth: PropTypes.number,
	mobileWidth: PropTypes.number,
};

export default Slider;
