import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useDeviceDetect from '../../../../util/use-device-detect';

const StyledSlideCounter = styled.div.attrs((props) => ({
	style: {
		opacity: props.cursor || props.mobile ? `1` : `0`,
		right: props.mobile && `6px`,
		left: !props.mobile && `${props.position.x}px`,
		top: props.mobile ? `5px` : `${props.position.y}px`,
	},
}))`
	position: ${(props) => (props.mobile ? `absolute` : `fixed`)};

	z-index: 50;
	pointer-events: none;
	color: var(--ts-blue);
	transition: opacity 0.15s ease-in-out;
	line-height: 1;

	${(props) =>
		!props.mobile
			? `
			top: 50%;
			left: 50%;

			span {
				display: block;
				transform: translate(-50%, -50%);
			}`
			: ``}

	@media (max-width: 29.9375em) {
		font-size: calc(15rem / 16);
	}

	@media (min-width: 30em) {
		font-size: calc(38rem / 16);
	}
`;

const SlideCounter = ({ slide, cursor, sliderRef }) => {
	const isMobile = useDeviceDetect();
	const [position, setPosition] = useState({ x: '50%', y: '50%' });

	const mouseRef = useRef();

	const handleMouseMove = useCallback(({ clientX, clientY }) => {
		setPosition({
			x: clientX,
			y: clientY,
		});
	}, []);

	useEffect(() => {
		mouseRef.current = handleMouseMove;
	});

	useEffect(() => {
		const slider = sliderRef.current;
		let unmounted = false;

		const mousemove = (event) => {
			if (!unmounted) {
				mouseRef.current(event);
			}
		};

		const mouseMove = slider.addEventListener('mousemove', mousemove);

		return () => {
			slider.removeEventListener('mousemove', mouseMove);
			unmounted = true;
		};
	}, [sliderRef]);

	return (
		<StyledSlideCounter
			position={position}
			cursor={cursor}
			mobile={isMobile}
		>
			<span aria-label="Current slide:">{slide()}</span>
		</StyledSlideCounter>
	);
};

SlideCounter.propTypes = {
	slide: PropTypes.func,
	cursor: PropTypes.string,
	sliderRef: PropTypes.objectOf(PropTypes.object),
};

export default SlideCounter;
