import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledArrow = styled.div`
	position: absolute;
	top: 0;
	${(props) => (props.direction === 'right' ? `right: 0;` : `left: 0;`)};
	height: 100%;
	width: 50%;
	cursor: none;

	button {
		outline: 0;
		background: none;
		padding: 0;
		margin: 0;
		border: none;

		&:focus {
			outline: none; /* not my call */
		}
	}
`;

const Arrow = ({ direction, handleClick }) => (
	<StyledArrow direction={direction} onClick={handleClick}>
		<button
			type="button"
			aria-label={direction === 'right' ? 'Next slide' : 'Previous slide'}
		/>
	</StyledArrow>
);

Arrow.propTypes = {
	direction: PropTypes.string,
	handleClick: PropTypes.func,
};

export default Arrow;
