import React from 'react';
import PropTypes from 'prop-types';
import Image from './image';
import Video from './video';
import SliderContainer from '../slider-container';

const components = {
	Video,
	Image,
	Slider: SliderContainer,
	Gallery: SliderContainer,
};

const ComponentWrapper = (props) => {
	const {
		payload,
		type,
		autoplay,
		maxWidth,
		image,
		placeholder,
		mobileWidth,
	} = props;
	const Component = components[type];

	return (
		<Component
			payload={payload}
			autoplay={autoplay}
			maxWidth={maxWidth}
			placeholder={placeholder || null}
			image={image || null}
			mobileWidth={mobileWidth || null}
		/>
	);
};

ComponentWrapper.propTypes = {
	payload: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	type: PropTypes.string,
	maxWidth: PropTypes.number,
	image: PropTypes.shape({
		retina: PropTypes.string,
		scale: PropTypes.string,
		alt: PropTypes.string,
	}),
	placeholder: PropTypes.string,
	autoplay: PropTypes.bool,
	mobileWidth: PropTypes.number,
};

export default ComponentWrapper;
