import { useState, useEffect } from 'react';

const useSafari = () => {
	const [isSafari, setSafari] = useState(false);

	useEffect(() => {
		const userAgent =
			typeof navigator === 'undefined' ? '' : navigator.userAgent;
		const safari = Boolean(
			userAgent.match(/^(?!.*Chrome).*(Instagram|Safari).*$/i)
		);
		setSafari(safari);
	}, []);

	return isSafari;
};

export default useSafari;
