import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTitle = styled.h1`
	--title-margin-bottom: 0.5em;
	margin-bottom: var(--title-margin-bottom);
	line-height: 1;

	@media (max-width: 26.9375em) {
		font-size: calc(21rem / 16);
		margin-bottom: calc(var(--title-margin-bottom) + 4px);

		flex: 1 0 100%;
	}

	@media (min-width: 27em) and (max-width: 29.9375em) {
		font-size: calc(30rem / 16);

		flex: 1 0 100%;
	}

	@media (min-width: 30em) {
		flex: 1 1 auto;
	}

	@media (min-width: 30em) and (max-width: 79.9375em) {
		font-size: calc(42rem / 16);
	}

	@media (min-width: 80em) {
		font-size: calc(58rem / 16);
	}
`;

const Title = (props) => {
	const { title } = props;
	if (title) {
		return <StyledTitle>{title}</StyledTitle>;
	}
	return null;
};

Title.propTypes = {
	title: PropTypes.string,
};

export default Title;
