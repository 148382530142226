import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentWrapper from './component-wrapper';

const StyledPrimaryContent = styled.div`
	display: grid;
	justify-items: center;
	grid-template-columns: 100%;

	@media (max-width: 63.9375em) {
		margin-bottom: calc(25rem / 16);
		grid-gap: calc(25rem / 16);
	}

	@media (min-width: 64em) {
		margin-bottom: calc(40rem / 16);
		grid-gap: calc(40rem / 16);
	}
`;

const PrimaryContent = (props) => {
	const { payload } = props;

	return (
		<StyledPrimaryContent>
			{payload.map((content, index) => (
				<ComponentWrapper
					type={content.hidden}
					payload={content.video_file || content.slider}
					placeholder={content.placeholder}
					key={`primary-content0${index}`}
					maxWidth={content.width}
				/>
			))}
		</StyledPrimaryContent>
	);
};

PrimaryContent.propTypes = {
	payload: PropTypes.arrayOf(PropTypes.object),
};

export default PrimaryContent;
