import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useSanitizeHtml from '../../util/use-sanitize-html';
import { sanitizeDefaults } from '../../util/util';

const StyledDescription = styled.section`
	margin-bottom: calc(26rem / 16);
	flex: 0 1 var(--description-flex-basis);

	@media (max-width: 53.9375em) {
		--description-flex-basis: calc(332vw * 100 / 375);
		margin-left: 1px;
	}

	@media (min-width: 54em) {
		--description-flex-basis: calc(1308vw * 100 / 1920);
		margin-bottom: calc(59rem / 16);
	}

	@media (max-width: 26.9375em) {
		font-size: calc(18rem / 16);
		line-height: calc(20rem / 16);
	}

	@media (min-width: 27em) and (max-width: 29.9375em) {
		font-size: calc(21rem / 16);
		line-height: calc(23rem / 16);
	}

	@media (min-width: 30em) and (max-width: 79.9375em) {
		font-size: calc(27rem / 16);
		line-height: calc(29rem / 16);
	}

	@media (min-width: 80em) {
		font-size: calc(34rem / 16);
		line-height: calc(37rem / 16);
	}

	& a {
		text-decoration: underline;
	}

	& a:hover,
	& a:focus {
		text-decoration: none;
	}
`;

const Description = (props) => {
	const { description } = props;
	const cleanDescription = useSanitizeHtml(description, sanitizeDefaults);

	if (description) {
		return (
			<StyledDescription
				dangerouslySetInnerHTML={{
					__html: cleanDescription,
				}}
			/>
		);
	}
	return null;
};

Description.propTypes = {
	description: PropTypes.string,
};

export default Description;
